@use './mixins';

// ************************************************************************************************
// This file contains AG Grid style overrides for the Balham theme. These MUST be set globally so
// overlays anchored to the body element (e.g. column drag indicators) receive the correct styles.
//
// To use:
// 1. Add AG Grid style imports to your app's styles.scss file:
//    @import 'ag-grid-community/styles/ag-grid.css';
//    @import 'ag-grid-community/styles/ag-theme-balham-no-font.min.css';
//    @import 'ag-grid-community/styles/agGridMaterialFont.min.css';
// 2. Import this file after the AG Grid imports:
//    @import '../../../../libs/shared/angular/utils/theming/src/styles/ag-theme-balham-overrides.scss';
// 3. Add the ag-theme-balham or ag-theme-balham-dark class to your grid's parent element.
// 4. To override CSS variables or styles on an app-by-app or grid-by-grid basis, add an additional
//    custom class to the grid container element (e.g. ag-theme-my-custom-table) and define your
//    variable and style overrides in it.
//
// Full AG Grid variable reference:
// https://www.ag-grid.com/angular-data-grid/global-style-customisation-variables/
// ************************************************************************************************

// Base styles that apply to both the light and dark themes
.ag-theme-balham-base {
	--ag-border-radius: 8px; // standard border radius
	--ag-borders-critical: 1px solid; // non-row border style
	--ag-header-column-separator-width: 1px;
	--ag-header-height: 36px;
	--ag-icon-font-family: 'agGridMaterial';
	--ag-row-border-width: 1px;
	--ag-row-height: 36px;

	--sh-ag-grid-tree-icon-size: 24px;
}

// Styles only applied to the Balham light (regular) theme
.ag-theme-balham {
	@extend .ag-theme-balham-base;

	// TODO: fill in with light theme styles where the Prism token used must differ.
	// In cases where the same token is used for both variants, move the variable
	// definition from .ag-theme-balham-dark to .ag-theme-balham-base.
}

// Styles only applied to the Balham dark theme
.ag-theme-balham-dark {
	@extend .ag-theme-balham-base;

	--ag-background-color: var(--prism-color-theme-surface-variant);
	--ag-balham-active-color: var(--prism-color-theme-accent-primary);
	--ag-border-color: var(--prism-color-theme-outline); // non-row border color
	--ag-checkbox-background-color: transparent;
	--ag-checkbox-checked-color: var(--prism-color-theme-accent-primary);
	--ag-checkbox-unchecked-color: var(--prism-color-theme-ink);
	--ag-checkbox-indeterminate-color: var(--prism-color-theme-accent-primary);
	--ag-foreground-color: var(--prism-color-theme-ink);
	--ag-header-foreground-color: var(--prism-color-theme-ink);
	--ag-header-background-color: var(--prism-color-theme-surface-variant);
	--ag-header-cell-hover-background-color: var(--prism-color-theme-surface-variant);
	--ag-header-column-separator-color: var(--prism-color-theme-outline);
	--ag-input-focus-border-color: var(--prism-color-theme-accent-primary);
	--ag-material-accent-color: var(--prism-color-theme-accent-primary);
	--ag-odd-row-background-color: var(--prism-color-theme-surface-variant);
	--ag-row-border-color: var(--prism-color-theme-outline); // row bottom border color
	--ag-row-hover-color: color-mix(
		in srgb,
		var(--prism-color-theme-surface-variant),
		var(--prism-color-theme-window-film-hover)
	);
	--ag-selected-row-background-color: color-mix(
		in srgb,
		var(--prism-color-theme-surface-variant),
		var(--prism-color-theme-outline)
	);

	--sh-ag-grid-dnd-ghost-background-color: var(--prism-color-theme-surface);
}

// Temporary extra class for updating a grid to use the DIN font. Once all apps sharing this theme have migrated to DIN,
// we can move these var assignments into .ag-theme-balham-base and move font-smoothing to the top-level .ag-cell override
.ag-font-din {
	--ag-font-family: var(--prism-typography-global-font-family-default);
	--ag-font-size: 13px;

	& .ag-cell {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// ************************************************************************************************
// Styles below this point are for specific AG Grid elements and apply to both the light and dark
// themes. While they can use any of the Prism tokens, usage of --ag-grid-*  and --sh-ag-grid-*
// CSS variables should be preferred to reduce duplication across themes.
// ************************************************************************************************

.ag-cell {
	display: flex;
	align-items: center;
}

.ag-cell-wrapper {
	height: 100%;
	width: 100%;
}

// Make column container expand to full width so that row borders aren't cut short
.ag-center-cols-container {
	min-width: 100%;
}

.ag-checkbox-input {
	cursor: pointer;
}

.ag-checkbox-input-wrapper:focus-within {
	box-shadow: none;
}

// These styles apply to the drag indicator that is shown when dragging a column to move it.
// Without `max-width`, it takes up the entire width of the screen. We also need to override
// `height` so that it can expand to fully show the drag indicator icon and column name.
.ag-dnd-ghost {
	width: 100%;
	max-width: 300px;
	height: auto !important;
	line-height: normal;
	padding: 4px;
	background-color: var(--sh-ag-grid-dnd-ghost-background-color);
}

.ag-group-value {
	height: 100%;
	width: 100%;
}

.ag-header-cell-text {
	font-weight: 700;
}

.ag-header-icon.ag-header-cell-menu-button {
	@include mixins.icon-interaction-states($icon-size: 16px, $bg-size: 28px);
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.ag-icon-tree-closed {
	font-size: var(--sh-ag-grid-tree-icon-size);
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.ag-icon-tree-open {
	font-size: var(--sh-ag-grid-tree-icon-size);
	display: flex !important;
	align-items: center;
	justify-content: center;
}

// Necessary for popups anchored to the body element to show on top of dialogs
.ag-popup-child {
	z-index: 9999;
}

.ag-row-group {
	align-items: center !important;
}

.ag-row-last {
	border-bottom: none;
}

.ag-row-selected.ag-row-hover {
	background-color: color-mix(in srgb, var(--ag-selected-row-background-color), var(--ag-row-hover-color));
}

.ag-selection-checkbox .ag-checkbox {
	@include mixins.icon-interaction-states($icon-size: 16px, $bg-size: 32px);
}
