/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@use './styles/theming.scss' as theming;
@use '../../../../libs/cloud/shared/utils/theming/src/styles/material-overrides/material-overrides.scss' as
	material-override;
@use '../../../../libs/cloud/shared/ui/components/src/lib/legacy-layout/structure.scss' as strucure;

@include theming.app-theming();
@include material-override.all-components-override-style();

@import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
@import '../../../../libs/cloud/shared/ui/components/src/lib/legacy-layout/material-override.scss';
@import '../../../../libs/cloud/device-management/devices/feature-inventory/src/lib/ui/components/devices.component.theme.scss';

/**
 * Prism design tokens
 */
@import '@shure/prism-web-components/dist/prism/prism.css';

/**
 * Prism icons
 */
@import '@shure/prism-design-tokens-css-icons-md/icons/help.css';
@import '@shure/prism-design-tokens-css-icons-md/icons/sh-error-outline.css';
@import '@shure/prism-design-tokens-css-icons-md/icons/sh-network.css';
@import '@shure/prism-design-tokens-css-icons-md/icons/sh-id-nobox.css';
@import '@shure/prism-design-tokens-css-icons-md/icons/settings.css';
@import '@shure/prism-design-tokens-css-icons-md/icons/stop.css';

/**
 * AG Grid styles
 */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham-no-font.min.css';
@import 'ag-grid-community/styles/agGridMaterialFont.min.css';
@import '../../../../libs/shared/angular/utils/theming/src/styles/ag-theme-balham-overrides.scss';

/*
 * Global HTML element overrides
 */
html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input {
	font-family: var(--prism-typography-global-font-family-default);
}

/*
 * Global classes and component overrides
 */
sh-root {
	display: flex;
	flex: 1;
	flex-direction: column;
	box-sizing: border-box;
	min-height: 100%;
	width: 100%;
	height: 100%;
}

.sh-custom-dialog-container .mat-mdc-dialog-container {
	padding: 0;
}

// Allow snackbar to determine it's own height and display on a single line
.mat-mdc-snack-bar-container {
	height: auto;
	max-width: 100vw;
}

// Positions snackbar with a vertical position of 'bottom' above the app shell footer
.sh-center-snackbar {
	margin-bottom: 60px;
}

.sh-center-text input {
	text-align: center;
}

// Override the top padding on the no rows overlay so that it appears centered in the grid
#sh-no-rows-overlay-div {
	margin-top: var(--ag-row-height) !important;
}

$sh-layout-padding: 24px !default;
$sh-content-padding: 24px !default;

.layout-padding,
.sh-main-content-container,
.sh-page-padding {
	padding: $sh-layout-padding;
	z-index: 0;
}
[sh-layout-padding],
.sh-layout-padding {
	padding: $sh-layout-padding;
}

[sh-content-padding],
.sh-content-padding {
	padding: $sh-content-padding;
}

/*
	Flex <router-outlet> elements by default allowing
	the routed components to flex as well
*/
router-outlet:not([noFlex]) {
	flex: none !important;

	+ * {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
}

sh-button-link {
	box-sizing: border-box !important;
	border-top: 2px solid transparent !important;
	border-bottom: 2px solid var(--mat-accent-darker) !important;
	padding: 0 2px !important;
	display: inline-block !important;
	border-radius: 0 !important;
	height: 24px !important;
	line-height: 22px !important;
	min-width: initial !important;
}

.sh-divider-horizontal {
	width: 100%;
	height: 1px;
	background-color: var(--prism-color-theme-outline);
}

/////////////////////////////////////////
/// TODO LBF 7/26/23 - pulled in from light-roboto.css - fix appropriately for Angular 15
.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
	font-family: var(--prism-typography-global-font-family-default) !important;
	font-size: 14px !important;
	font-weight: 600 !important;
}

.mat-mdc-raised-button:not([disabled]) {
	color: var(--mat-shure-dark-text) !important;
	background-color: var(--mat-shure-green) !important;
}

.mat-expansion-panel-header {
	font-family: var(--prism-typography-global-font-family-default) !important;
	font-size: 13px !important;
	font-weight: 400 !important;
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
	background: #222222;
}
@media (hover: none) {
	.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
		background: #fff;
	}
}
.mat-expansion-panel-header-title {
	color: #fbfbfb;
}
.mat-expansion-indicator:after,
.mat-expansion-panel-header-description {
	color: #fbfbfb;
}
.mat-expansion-panel-header[aria-disabled='true'] {
	color: rgba(0, 0, 0, 0.38);
}
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title {
	color: inherit;
}
.mat-expansion-panel-header {
	height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
	height: 64px;
}

.mat-mdc-input-element {
	font-size: 16px !important;
	color: var(--mat-shure-dark-input) !important;
}

/////////////////////////////////////////
.sh-button-link:hover {
	background-color: var(--mat-accent-default) !important;
	border-bottom: 2px solid var(--mat-accent-default) !important;
}

.sh-button-link:focus-visible {
	padding: 0 !important;
	border: 2px solid var(--mat-accent-darker) !important;
}
.sh-button-link:active {
	background-color: var(--mat-accent-lighter) !important;
	border-bottom: 2px solid var(--mat-accent-lighter) !important;
}
.sh-button-link[disabled] {
	pointer-events: none;
	border-bottom: 2px dotted var(--mat-foreground-disabled) !important;
}

.sh-main-content-container [class*='mat-elevation-z4'] {
	border-radius: 8px;
	overflow: hidden;
}

.mat-mdc-tooltip {
	white-space: pre-wrap;
}

.sh-ellipsis {
	text-overflow: ellipsis !important;
	overflow: hidden !important;
	white-space: nowrap !important;
}

.sh-ellipsis-white-space-none {
	white-space: none !important;
}
.mat-primary.mat-mdc-button-base {
	height: 32px !important;
}
.mat-icon {
	vertical-align: top;
}

/*
 * GLOBAL COMPONENT STYLES
 * These styles are used across CDM on various components. Until we have a full design-system/component library,
 * we can just add them here.
 */

// Button with green text and transparent background that is visible on hover
// Use with mat-button directive
.mat-mdc-button.sh-button-ghost {
	&:not(:disabled) {
		color: var(--prism-color-theme-accent-primary);
	}

	&[disabled] {
		color: var(--prism-color-theme-disabled-contrast);
	}
}

style {
	display: none !important;
}
